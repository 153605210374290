@media screen and (max-width: 360px) {
    .products-mkit-img {
        height: 50vw;
        margin-left: -0.5rem;
        margin-top: -5rem;
        margin-bottom: 0rem;
    }
}
@media  screen and (min-width: 360px) {
    .products-mkit-img {
        height: 60vw;
        margin-top: -5rem;
        margin-bottom: -1rem;
    }
}
@media screen and (min-width: 576px) {
    .products-mkit-img {
        height: 20rem;
        margin-top: -7rem;
        margin-bottom: -2rem;
    }
}
@media screen and (min-width: 768px) {
    .products-mkit-img {
        height: 30rem;
        margin-top: -9rem;
        margin-bottom: -3rem;
    }
}
@media screen and (min-width: 992px) {
    .products-mkit-img {
        height: 24rem;
        margin: 0;
        position: absolute;
        top: 8rem;
        right: -2rem;
    }
}
@media screen and (min-width: 1200px) {
    .products-mkit-img {
        height: 32rem;
        margin: 0;
        position: absolute;
        top: 5rem;
        right: -2rem;
    }
}
@media screen and (min-width: 1400px) {
    .products-mkit-img {
        height: 38rem;
        margin: 0;
        position: absolute;
        top: 2rem;
        right: -3rem;
    }
}




@media screen and (max-width: 768px) {
    .products-mkit-hero-bg-img {
        background-color: var(--divelit-grey-90);
        max-width: 100%;
        height: auto;
    
        max-width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        /*border-bottom: 1px solid var(--divelit-grey-80);*/
    }
    .products-mkit-hero-bg-img::after {
        content: "";
        /*filter: brightness(60%);*/

        background: linear-gradient(90deg, rgba(26, 26, 26, 0.5) 0%, rgba(26, 26, 26, 0.20) 10%, rgba(26, 26, 26, 0.20) 40%, rgba(26, 26, 26, 0.25) 100%), linear-gradient(45deg, rgba(26, 26, 26, 0.5) 0%, rgba(26, 26, 26, 0.5) 20%, rgba(26, 26, 26, 0.25) 60%, rgba(26, 26, 26, 0.25) 100%), url("../../images/pages/products/m-kit/mkit.png");
        background-size: 100vw;
        background-position: center center;
        background-repeat: no-repeat;

        position: absolute;
        top: 5rem;
        left: 0rem;
        bottom: 0;
        right: 0;
        z-index: -1;
        
    }
}
@media screen and (min-width: 768px) {

    .products-mkit-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0.2) 10%, rgba(26, 26, 26, 0.1) 40%, rgba(26, 26, 26, 0.05) 100%), linear-gradient(45deg, rgba(26, 26, 26, 0.2) 0%, rgba(26, 26, 26, 0.1) 20%, rgba(26, 26, 26, 0.05) 60%, rgba(26, 26, 26, 0.0) 100%), url("../../images/pages/products/m-kit/mkit.png");
        background-size: 35rem;
        background-position: 110% 80%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 992px) {
    .products-mkit-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0.2) 10%, rgba(26, 26, 26, 0.1) 20%, rgba(26, 26, 26, 0.05) 100%), linear-gradient(45deg, rgba(26, 26, 26, 0.2) 0%, rgba(26, 26, 26, 0.1) 20%, rgba(26, 26, 26, 0.05) 50%, rgba(26, 26, 26, 0.0) 100%), url("../../images/pages/products/m-kit/mkit.png");
        background-size: 35rem;
        background-position: 90% bottom;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1200px) {
    .products-mkit-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0.2) 10%, rgba(26, 26, 26, 0.1) 40%, rgba(26, 26, 26, 0.05) 100%), linear-gradient(45deg, rgba(26, 26, 26, 0.2) 0%, rgba(26, 26, 26, 0.1) 20%, rgba(26, 26, 26, 0.05) 60%, rgba(26, 26, 26, 0.0) 100%), url("../../images/pages/products/m-kit/mkit.png");
        background-size: 40rem;
        background-position: 90% bottom;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 1400px) {
    .products-mkit-hero-bg-img {
        background: linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0.2) 10%, rgba(26, 26, 26, 0.1) 40%, rgba(26, 26, 26, 0.05) 100%), linear-gradient(45deg, rgba(26, 26, 26, 0.2) 0%, rgba(26, 26, 26, 0.1) 20%, rgba(26, 26, 26, 0.05) 60%, rgba(26, 26, 26, 0.0) 100%), url("../../images/pages/products/m-kit/mkit.png");
        background-size: 50rem;
        background-position: 90% center;
        background-repeat: no-repeat;
    }
}






@media screen and (max-width: 360px) {
    .products-mkit-martin-img {
        width: 60vw;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 60%, rgba(26, 26, 26, 0.9) 75%, rgba(26, 26, 26, 0.001) 98%, rgba(26, 26, 26, 0.001) 100%);
    }
}
@media screen and (min-width: 360px) {
    .products-mkit-martin-img {
        width: 20rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 60%, rgba(26, 26, 26, 0.9) 75%, rgba(26, 26, 26, 0.001) 98%, rgba(26, 26, 26, 0.001) 100%);
    }
}
@media screen and (min-width: 576px) {
    .products-mkit-martin-img {
        width: 25rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 60%, rgba(26, 26, 26, 0.9) 80%, rgba(26, 26, 26, 0.001) 95%, rgba(26, 26, 26, 0.001) 100%);
    }
}
@media screen and (min-width: 768px) {
    .products-mkit-martin-img {
        width: 28rem;
        position: relative;
        left: -10rem;
        top: 3rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.95) 80%, rgba(26, 26, 26, 0.001) 100%);
    }
}
@media screen and (min-width: 992px) {
    .products-mkit-martin-img {
        width: 30rem;
        position: relative;
        left: -5rem;
        top: 0rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.95) 80%, rgba(26, 26, 26, 0.001) 100%);

    }
}
@media screen and (min-width: 1200px) {
    .products-mkit-martin-img {
        width: 30rem;
        position: initial;
        left: 0rem;
        top: 0rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.95) 80%, rgba(26, 26, 26, 0.001) 100%);

    }
}
@media screen and (min-width: 1400px) {
    .products-mkit-martin-img {
        width: 34rem;
        /*margin-top: -10rem;*/
        margin-left: 0rem;
        margin-top: 0rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.95) 80%, rgba(26, 26, 26, 0.001) 100%);

    }
}

@media screen and (min-width: 2000px) {
    .products-mkit-martin-img {
        width: 35rem;
        margin-left: 0rem;
        margin-top: 0rem;
        mask-image: linear-gradient(180deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.95) 80%, rgba(26, 26, 26, 0.001) 100%);
    }
}


.products-mkit-href {
    text-decoration: none !important;
}

.products-mkit-href:hover {
    background-color: var(--divelit-grey-30);
}